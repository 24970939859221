import { Box, Flex } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import ChainIndicators from 'ui/home/indicators/ChainIndicators';
import LatestBlocks from 'ui/home/LatestBlocks';
import LatestZkEvmL2Batches from 'ui/home/LatestZkEvmL2Batches';
import Stats from 'ui/home/Stats';
import Transactions from 'ui/home/Transactions';
import AdBanner from 'ui/shared/ad/AdBanner';

const rollupFeature = config.features.rollup;

const Home = () => {
  const hideAdd = false; //! HIDDEN FOR CUSTOM DESIGN

  return (
    <Box as="main">
      <Flex
        flexWrap="wrap"
        w="100%"
        flexDirection={{ base: 'column', xl: 'row' }}
        alignItems={{ base: 'center', xl: 'normal' }}
        mt={{ base: 4, lg: 8 }}
      >
        <ChainIndicators/>
        <Stats/>
      </Flex>
      { hideAdd && <AdBanner mt={{ base: 6, lg: 8 }} mx="auto" display="flex" justifyContent="center"/> }
      <Flex mt={ 8 } direction={{ base: 'column' }}>
        { rollupFeature.isEnabled && rollupFeature.type === 'zkEvm' ? <LatestZkEvmL2Batches/> : <LatestBlocks/> }
        <Box flexGrow={ 1 }>
          <Transactions/>
        </Box>
      </Flex>
    </Box>
  );
};

export default Home;
