import type { BoxProps } from '@chakra-ui/react';
import { Box, Flex, Grid, Skeleton, Text } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import { motion } from 'framer-motion';
import React from 'react';

import type { Block } from 'types/api/block';
import { ViewModes } from 'types/views/viewModes';

import config from 'configs/app';
import getNetworkValidatorTitle from 'lib/networks/getNetworkValidatorTitle';
import getDefaultTransitionProps from 'theme/utils/getDefaultTransitionProps';
import getStylesByColorScheme from 'theme/utils/getStylesByColorScheme';
import BlockTimestamp from 'ui/blocks/BlockTimestamp';
import AddressEntity from 'ui/shared/entities/address/AddressEntity';
import BlockEntityL3 from 'ui/shared/entities/block/BlockEntityL3';
import Utilization from 'ui/shared/Utilization/Utilization';

type Props = {
  block: Block;
  isLoading?: boolean;
  viewMode?: ViewModes;
  themeStyles?: BoxProps;
};

const COLOR_SCHEME_BY_BLOCK_TYPE = {
  block: 'blue',
  reorg: 'orange',
  uncle: 'green',
};

const STYLES_BY_VIEW_MODE = {
  [ViewModes.CARDS]: {
    width: '327px',
  },
  [ViewModes.LIST]: {
    width: '100%',
  },
};

const LatestBlocksItem = ({ block, isLoading, viewMode = ViewModes.CARDS, themeStyles = {} }: Props) => {
  const percentageOfUsedGas = getPercentageOfNumber(block.gas_used, block.gas_limit);
  const percentageValue = getPercentageValue(block.gas_used, block.gas_limit);
  const transaction = getDefaultTransitionProps();

  const colorScheme = COLOR_SCHEME_BY_BLOCK_TYPE[block.type];
  const blockStyles = getStylesByColorScheme(colorScheme);
  const viewStyles = STYLES_BY_VIEW_MODE[viewMode];

  const SKELETON_LIST: Array<Omit<SkeletonItemProps, 'isLoading' | 'color'>> = [
    {
      title: 'Transactions',
      content: block.tx_count,
    },
    {
      title: 'Size',
      content: `${ block.size } Bytes`,
    },
    {
      title: 'Time',
      content: <BlockTimestamp
        ts={ block.timestamp }
        isEnabled={ !isLoading }
        isLoading={ isLoading }
        fontSize="sm"
        flexShrink={ 0 }
        textAlign="right"
        color="white"
      />,
    },
    {
      title: 'Gas Used',
      content: <span>
        { `${ formatNumber(block.gas_used) } (${ percentageOfUsedGas })` }
        <Text as="span" color="gray.100"> / { formatNumber(block.gas_limit) }</Text>
      </span>,
    },
  ];

  return (
    <Box
      w={ viewStyles.width }
      as={ motion.div }
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ display: 'none' }}
      _last={{ mr: 0 }}
      transitionDuration="normal"
      transitionTimingFunction="linear"
      { ...themeStyles }
    >
      <Flex
        justifyContent="space-between"
        borderTopRadius={ 16 }
        bg={ isLoading ? 'whiteAlpha.100' : blockStyles.gradient }
        paddingX={ 6 }
        paddingY={ 4 }
      >
        <Skeleton
          isLoaded={ !isLoading }
          w="full"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          textTransform="capitalize"
          fontWeight={ 500 }
        >
         Block
          <BlockEntityL3
            number={ block.height }
            fontSize="l"
            lineHeight="19.2px"
            fontWeight={ 700 }
          />
        </Skeleton>

      </Flex>
      <Grid
        borderBottomRadius={ 16 }
        p={ 5 }
        gridGap={ 2.5 }
        bg="blue.700"
        templateColumns={{
          base: 'auto',
        }}
        fontSize="sm"
        sx={{
          '& > div:nth-of-type(even)': {
            textAlign: 'right',
          },
        }}
      >
        { !config.features.rollup.isEnabled &&
          !config.UI.views.block.hiddenFields?.miner && (
          <>
            <Skeleton isLoaded={ !isLoading } textTransform="capitalize">
              { getNetworkValidatorTitle() }
            </Skeleton>
            <AddressEntity
              w="100%"
              justifyContent="end"
              color={ blockStyles.color }
              _hover={
                {
                  color: 'link_hovered',
                }
              }
              fontWeight={ 600 }
              marginLeft="auto"
              address={ block.miner }
              isLoading={ isLoading }
              noIcon
              noCopy
              truncation="constant"
              { ...transaction }
            />
          </>
        ) }

        { SKELETON_LIST.map(({ title, content }) => (
          <SkeletonItem
            key={ title }
            title={ title }
            content={ content }
            isLoading={ Boolean(isLoading) }/>
        )) }

        <Box as="div" gridColumn="span 2" w="100%">
          <Utilization colorScheme={ colorScheme } value={ percentageValue } isLoading={ isLoading }/>
        </Box>
      </Grid>
    </Box>
  );
};

type SkeletonItemProps = {
  title: string;
  content: JSX.Element | string | number;
  isLoading: boolean;
}

const SkeletonItem = ({ title, content, isLoading }: SkeletonItemProps) => (
  <>
    <Skeleton isLoaded={ !isLoading }>{ title }</Skeleton>
    <Skeleton isLoaded={ !isLoading }>
      { content }
    </Skeleton>
  </>
);

const getPercentageOfNumber = (
  num1: number | string | null,
  num2: number | string | null,
): string => {
  const n1 = Number(num1);
  const n2 = Number(num2);

  return new BigNumber(n1).multipliedBy(100).dividedBy(n2).toFixed(2) + '%';
};

const getPercentageValue = (num1: number | string | null,
  num2: number | string | null): number => {
  const n1 = Number(num1);
  const n2 = Number(num2);
  return new BigNumber(n1).dividedBy(n2).toNumber();
};

const formatNumber = (str: string | null): string | null => {
  return str ? str.replace(/\B(?=(\d{3})+(?!\d))/g, ',') : str;
};

export default LatestBlocksItem;
