import { chakra } from '@chakra-ui/react';
import React from 'react';

const COUNTER_OVERLOAD = 50;

type Props = {
  count?: number | null;
}

const TabCounter = ({ count }: Props) => {

  if (count === undefined || count === null) {
    return null;
  }

  return (
    <chakra.span
      ml={ 1 }
    >
      ({ count > COUNTER_OVERLOAD ? `${ COUNTER_OVERLOAD }+` : count })
    </chakra.span>
  );
};

export default TabCounter;
