import { Box, Flex, Text } from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';

import type { SocketMessage } from 'lib/socket/types';
import type { Block } from 'types/api/block';

import { route } from 'nextjs-routes';

import useApiQuery, { getResourceKey } from 'lib/api/useApiQuery';
import useSocketChannel from 'lib/socket/useSocketChannel';
import useSocketMessage from 'lib/socket/useSocketMessage';
import { BLOCK } from 'stubs/block';
import H2 from 'ui/shared/h2/H2';
import LinkInternal from 'ui/shared/LinkInternal';

import LatestBlocksItem from './LatestBlocksItem';

const blocksMaxCount = 16;

const LatestBlocks = () => {
  const { data, isPlaceholderData, isError } = useApiQuery('homepage_blocks', {
    queryOptions: {
      placeholderData: Array(blocksMaxCount).fill(BLOCK),
    },
  });

  const queryClient = useQueryClient();

  const handleNewBlockMessage: SocketMessage.NewBlock['handler'] = React.useCallback((payload) => {
    queryClient.setQueryData(getResourceKey('homepage_blocks'), (prevData: Array<Block> | undefined) => {

      const newData = prevData ? [ ...prevData ] : [];

      if (newData.some((block => block.height === payload.block.height))) {
        return newData;
      }

      return [ payload.block, ...newData ].sort((b1, b2) => b2.height - b1.height).slice(0, blocksMaxCount);
    });
  }, [ queryClient ]);

  const channel = useSocketChannel({
    topic: 'blocks:new_block',
    isDisabled: isPlaceholderData || isError,
  });
  useSocketMessage({
    channel,
    event: 'new_block',
    handler: handleNewBlockMessage,
  });

  let content;

  if (isError) {
    content = <Text>No data. Please reload page.</Text>;
  }

  if (data) {
    const dataToShow = data.slice(0, blocksMaxCount);

    content = (
      <Swiper
        slidesPerView={ 1 }
        slidesPerGroup={ 1 }
        spaceBetween={ 24 }
        pagination={{
          clickable: true,
        }}
        modules={ [ Pagination ] }
        className="mySwiper"
        breakpoints={{
          '700': {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 24,
          },
          '1024': {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 24,
          },
          '1440': {
            slidesPerView: 4,
            slidesPerGroup: 4,
            spaceBetween: 24,
          },
        }}
      >
        { dataToShow.map(((block, index) => (
          <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }} key={ block.height + (isPlaceholderData ? String(index) : '') }
          >
            <LatestBlocksItem
              key={ block.height + (isPlaceholderData ? String(index) : '') }
              block={ block }
              isLoading={ isPlaceholderData }
            />
          </SwiperSlide>
        ))) }
      </Swiper>
    );
  }

  return (
    <Box width={{ base: '100%' }} flexShrink={ 0 }>
      <Flex justifyContent="space-between" mt={ 10 } mb={ 8 }>
        <H2>Blocks</H2>
        <Flex justifyContent="center">
          <LinkInternal href={ route({ pathname: '/blocks' }) } variant="button" fontSize="sm" >View all blocks</LinkInternal>
        </Flex>
      </Flex>
      { content }
    </Box>
  );
};

export default LatestBlocks;
