import { Box, Flex, Skeleton, Text, useColorModeValue } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import { HOMEPAGE_STATS } from 'stubs/stats';
import BorderGradient from 'ui/shared/borderGradient/borderGradient';
import Hint from 'ui/shared/Hint';
import IconSvg from 'ui/shared/IconSvg';

import ChainIndicatorChartContainer from './ChainIndicatorChartContainer';
import ChainIndicatorItem from './ChainIndicatorItem';
import useFetchChartData from './useFetchChartData';
import INDICATORS from './utils/indicators';

const indicators = INDICATORS
  .filter(({ id }) => config.UI.homepage.charts.includes(id))
  .sort((a, b) => {
    if (config.UI.homepage.charts.indexOf(a.id) > config.UI.homepage.charts.indexOf(b.id)) {
      return 1;
    }

    if (config.UI.homepage.charts.indexOf(a.id) < config.UI.homepage.charts.indexOf(b.id)) {
      return -1;
    }

    return 0;
  });

const ChainIndicators = () => {
  const [ selectedIndicator, selectIndicator ] = React.useState(indicators[0]?.id);
  const indicator = indicators.find(({ id }) => id === selectedIndicator);

  const queryResult = useFetchChartData(indicator);
  const statsQueryResult = useApiQuery('stats', {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: HOMEPAGE_STATS,
    },
  });

  const listBgColorDesktop = useColorModeValue('blue.600', 'blue.600');
  const bgColor = useColorModeValue('gradients.primary', 'gradients.primary');

  if (indicators.length === 0) {
    return null;
  }

  const valueTitle = (() => {
    if (statsQueryResult.isPlaceholderData) {
      return <Skeleton h="24px" w="215px" mt={ 3 } mb={ 4 }/>;
    }

    if (!statsQueryResult.data) {
      return <Text mt={ 3 } mb={ 4 }>There is no data</Text>;
    }

    return (
      <Text
        bg="gradients.gold"
        backgroundClip="text"
        fontWeight={ 800 }
        fontFamily="heading"
        fontSize="32px"
        lineHeight="48.4px"
        mt={ 2 }
      >
        { indicator?.value(statsQueryResult.data) }
      </Text>
    );
  })();

  const valueDiff = (() => {
    if (!statsQueryResult.data || !indicator?.valueDiff) {
      return null;
    }

    const diff = indicator.valueDiff(statsQueryResult.data);
    if (diff === undefined || diff === null) {
      return null;
    }

    const diffColor = diff >= 0 ? 'green.500' : 'red.500';

    return (
      <Skeleton isLoaded={ !statsQueryResult.isPlaceholderData } display="flex" alignItems="center" color={ diffColor } mt={ 2 }>
        <IconSvg name="up" boxSize={ 5 } mr={ 1 } transform={ diff < 0 ? 'rotate(180deg)' : 'rotate(0)' }/>
        <Text color={ diffColor } fontWeight={ 600 }>{ diff }%</Text>
      </Skeleton>
    );
  })();

  return (
    <BorderGradient
      maxW="820px"
      w="100%"
      mr={{ base: 0, lg: 6 }}
    >
      <Flex
        w="100%"
        h="100%"
        p={{ base: 0, lg: 8 }}
        borderRadius={{ base: 'lg' }}
        boxShadow={{ base: 'none', lg: 'xl' }}
        bg={{ base: bgColor }}
        rowGap={ 0 }
        flexDir={{ base: 'column', lg: 'row' }}
        alignItems="stretch"
      >
        <Flex flexGrow={ 1 } flexDir="column" order={{ base: 2, lg: 1 }} p={{ base: 6, lg: 0 }}>
          <Flex alignItems="center">
            <Text color="white" fontWeight={ 600 } fontFamily="heading" fontSize="2xl">{ indicator?.title }</Text>
            { indicator?.hint && <Hint label={ indicator.hint } ml={ 1 }/> }
          </Flex>
          <Box mb={ 4 }>
            { valueTitle }
            { valueDiff }
          </Box>
          <ChainIndicatorChartContainer { ...queryResult }/>
        </Flex>
        { indicators.length > 1 && (
          <Flex
            h="max-content"
            flexShrink={ 0 }
            flexDir="column"
            as="ul"
            p={ 3 }
            borderRadius="lg"
            bgColor={{ base: listBgColorDesktop }}
            rowGap={ 3 }
            order={{ base: 1, lg: 2 }}
          >
            { indicators.map((indicator) => (
              <ChainIndicatorItem
                key={ indicator.id }
                { ...indicator }
                isSelected={ selectedIndicator === indicator.id }
                onClick={ selectIndicator }
                stats={ statsQueryResult }
              />
            )) }
          </Flex>
        ) }
      </Flex>
    </BorderGradient>
  );
};

export default ChainIndicators;
