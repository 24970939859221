import type { FlexProps } from '@chakra-ui/react';
import { Box } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
import React from 'react';

const BORDER_WIDTH = '1px';

const BorderGradient = ({
  children,
  ...props
}: PropsWithChildren<
Omit<FlexProps, 'justifyContent' | 'alignItems' | 'p' | 'padding'>
>) => {
  const bg = props.bg ?? 'gradients.borderGradient';
  const borderRadius = props.borderRadius ?? 'lg';

  return (
    <Box borderRadius={ borderRadius } bg={ bg } p={ BORDER_WIDTH } { ...props }>
      { children }
    </Box>
  );
};

export default BorderGradient;
