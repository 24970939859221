import type { SystemStyleObject } from '@chakra-ui/react';
import { Skeleton, Flex, useColorModeValue, chakra } from '@chakra-ui/react';
import React from 'react';

import breakpoints from 'theme/foundations/breakpoints';
import BorderGradient from 'ui/shared/borderGradient/borderGradient';
import type { IconName } from 'ui/shared/IconSvg';

type Props = {
  icon: IconName;
  title: string;
  value: string | React.ReactNode;
  className?: string;
  tooltip?: React.ReactNode;
  url?: string;
  isLoading?: boolean;
}

const LARGEST_BREAKPOINT = '1240px';

const StatsItem = ({ title, value, className, tooltip, url, isLoading }: Props) => {
  const sxContainer: SystemStyleObject = {
    [`@media screen and (min-width: ${ breakpoints.lg }) and (max-width: ${ LARGEST_BREAKPOINT })`]: { flexDirection: 'column' },
  };

  const sxText: SystemStyleObject = {
    [`@media screen and (min-width: ${ breakpoints.lg }) and (max-width: ${ LARGEST_BREAKPOINT })`]: { alignItems: 'center' },
  };

  const bgColor = useColorModeValue('gradients.primary', 'gradients.primary');
  const borderGradient = useColorModeValue('gradients.borderGradient', 'gradients.borderGradient');
  const loadingBgColor = useColorModeValue('blackAlpha.50', 'whiteAlpha.50');

  return (
    <BorderGradient w="100%" bg={ isLoading ? loadingBgColor : borderGradient }>
      <Flex
        h="100%"
        margin="auto"
        bg={ isLoading ? loadingBgColor : bgColor }
        padding={ 7 }
        borderRadius="lg"
        sx={ sxContainer }
        alignItems="center"
        justifyContent="center"
        columnGap={ 2 }
        className={ className }
        color={ useColorModeValue('black', 'white') }
        position="relative"
        { ...(url && !isLoading ? {
          as: 'a',
          href: url,
        } : {}) }
      >
        <Flex
          flexDirection="column"
          alignItems="center"
          textAlign="center"
          sx={ sxText }
        >
          <Skeleton isLoaded={ !isLoading } color="text_secondary" textTransform="capitalize" lineHeight="18px">
            <span>{ title }</span>
          </Skeleton>
          <Skeleton isLoaded={ !isLoading } fontSize="2xl" fontWeight={ 600 } color={ useColorModeValue('white', 'white') }>
            { typeof value === 'string' ? <span>{ value }</span> : value }
          </Skeleton>
        </Flex>
        { tooltip }
      </Flex>
    </BorderGradient>
  );
};

export default chakra(StatsItem);
