import type { HeadingProps } from '@chakra-ui/react';
import { Heading } from '@chakra-ui/react';
import type { PropsWithChildren } from 'react';
import React, { forwardRef } from 'react';

const H2 = forwardRef<HTMLHeadingElement, PropsWithChildren<Omit<HeadingProps, 'as' | 'size'>>>(({
  children,
  ...props
}, ref) => {
  return (
    <Heading
      { ...props }
      ref={ ref }
      bg="gradients.gold"
      backgroundClip="text"
      as="h2"
      fontWeight={ 700 }
    >
      { children }
    </Heading>
  );
});

H2.displayName = 'Heading<H2>';

export default H2;
